<template>
    <v-container fluid fill-height>
        <v-row justify="center"> JOBS </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            key: 0,
        }
    },
    mounted() {},
    actions: {},
}
</script>

<style></style>
